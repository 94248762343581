import { gql } from "@apollo/client"

export const GET_PURCHASE_QUERY = gql`
  query getPurchase($id: uuid!) {
    user_purchases_by_pk(id: $id) {
      currency
      created_at
      description
      id
      item_id
      price
      state
      item_type
      user_id
      VIDEO: video {
        id
        title
        subtitle
        short_description
        duration
        price
        price_policy
        landscape {
          s3_key
        }
      }
      COURSE: course {
        id
        title
        subtitle
        short_description
        price
        price_policy
        landscape {
          s3_key
        }
      }
      CERTIFICATION: certification {
        id
        title
        subtitle
        short_description
        price
        landscape_success {
          s3_key
        }
        landscape_locked {
          s3_key
        }
        wallpaper {
          s3_key
        }
      }
      GIFT_CARD: gift_card {
        id
        title
        subtitle
        short_description
        price
        criterion {
          picture {
            s3_key
          }
        }
      }
      BUNDLE: bundle {
        id
        title
        subtitle
        short_description
        price
        landscape {
          s3_key
        }
      }
    }
  }
`
export const GET_INSTALLMENTS_QUERY = gql`
  query getInstallments {
    external_payments {
      provider
      status
      user_purchase {
        item_type
        VIDEO: video {
          id
          title
          price
          landscape {
            s3_key
          }
        }
        COURSE: course {
          id
          title
          price
          landscape {
            s3_key
          }
        }
        CERTIFICATION: certification {
          id
          title
          price
          landscape_success {
            s3_key
          }
          landscape_locked {
            s3_key
          }
          wallpaper {
            s3_key
          }
        }
        GIFT_CARD: gift_card {
          id
          title
          price
          criterion {
            picture {
              s3_key
            }
          }
        }
        BUNDLE: bundle {
          id
          title
          price
          landscape {
            s3_key
          }
        }
      }
    }
  }
`
