import React from "react"
import PageRoot from "../../../components/PageRoot"
import ContainerWithPadding from "../../../components/ContainerWithPadding"
import { Button, Card, Divider, Skeleton } from "antd"
import { Link } from "gatsby"
import { useTranslation } from "react-i18next"
import QueryString from "query-string"
import { getImageUrl, translatePath } from "../../../utils"
import { useQuery } from "@apollo/client"
import { DEFAULT_LANDSCAPE_IMAGE_SIZE } from "../../../constants/imageSizes"
import { GET_PURCHASE_QUERY } from "../../../constants/queries"

const SuccessScalapay = ({ location }) => {
  const { t } = useTranslation()

  const params = QueryString.parse(location.search)
  const { purchaseId } = params
  const { loading, data } = useQuery(GET_PURCHASE_QUERY, {
    variables: { id: purchaseId },
  })

  const { item_type } = data?.user_purchases_by_pk || {}

  const item = data?.user_purchases_by_pk[item_type]

  const {
    title,
    short_description,
    price,
    landscape,
    landscape_locked,
    landscape_success,
    criterion,
  } = item || {}

  const coverUrl = getImageUrl(
    landscape_locked || landscape_success || landscape || criterion?.picture,
    DEFAULT_LANDSCAPE_IMAGE_SIZE
  )

  return (
    <>
      <PageRoot title={t("label:scalapayCheckoutSuccess")} isDark={false}>
        <div className="checkout-form-wrapper">
          <ContainerWithPadding size="small" align="center">
            {loading || !item ? (
              <Skeleton active />
            ) : (
              <div className="purchase-preview-wrapper">
                <Card
                  style={{ width: "100%", marginBottom: 50 }}
                  cover={<img alt={title} src={coverUrl} />}
                  bordered={false}
                >
                  <Card.Meta title={title} description={short_description} />
                </Card>
              </div>
            )}
            <h2>{t("message:scalapayPurchaseRequest")}</h2>
            <p>{t("message:scalapayPurchaseRequestDescription")}</p>
            <Button className="yellowsp" type="primary">
              <Link to={translatePath("/me/library")}>
                {t("button:goToMyLibrary")}
              </Link>
            </Button>
            <br />
            <br />
            <Divider>{t("message:or")}</Divider>
            <p>
              <Link className="home-link" to="/">
                {t("button:goMembership")}
              </Link>
            </p>
          </ContainerWithPadding>
        </div>
      </PageRoot>
    </>
  )
}

export default SuccessScalapay
